const scrollTop = document.getElementById("scrolltop");

const scrollFunction = () => {
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    scrollTop.style.display = "block";
  } else {
    scrollTop.style.display = "none";
  }
}
scrollFunction();

const scrollToTop = () => {
  window.scrollTo({ top: 0, left: 100, behavior: 'smooth' });
};

$('a[href^="#"]').on('click', function (e) {
  e.preventDefault();
  var id = $(this).attr('href'),
    targetOffset = $(id).offset().top;

  window.scrollTo({ top: targetOffset - 60, left: 100, behavior: 'smooth' });
  if ($('#navbar').hasClass('show')) {
    $('#navbar').removeClass('show')
  }

});

$('#form_contact').submit(function (e) {
  e.preventDefault();
  const data = $('#form_contact').serializeArray().reduce((acc, data) => { acc[data.name] = data.value; return acc }, {})

  $.ajax({
    url: 'https://maker.ifttt.com/trigger/send_form/with/key/b_DA7mc_Eh5Du6YePBJn4FUBMXdGRJ1Au43lSiw0AB1',
    type: 'POST',
    contentType: 'application/json',
    dataType: 'json',
    data: { "value1": `Nome: <p style=\"font-size: 18px; font-weight: bold\">${data.name}</p><br>Email: <p style=\"font-size: 18px; font-weight: bold\">${data.email}</p><br>Assunto: <p style=\"font-size: 18px; font-weight: bold\">${data.subject}</p><br><br>${data.message}` },
    success: function (result) {
      console.log(result);
      $('#form_contact')[0].reset();
    },
    error: function (xhr, resp, text) {
      console.error(xhr, resp, text);
    }
  })
});

scrollTop.onclick = scrollToTop;
window.onscroll = scrollFunction;
